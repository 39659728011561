import React, { Component } from "react";
import HalUI from "@dxc-technology/halstack-browser";
import "./App.css";


const YOUR_API_KEY = 'eyJraWQiOiJVc3VhR2NGWWx4K0RFQnpnWjVWeHoxYjFzcnNaTTBMNWZsbk5vdFJHXC8wST0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI5Mjg3YTI2Ny1mMzVhLTQxNGYtYTA3OC1mMGMxMGY4ODIyYjUiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGhlYXN0LTEuYW1hem9uYXdzLmNvbVwvYXAtc291dGhlYXN0LTFfTXlhWHV5T0pDIiwiY2xpZW50X2lkIjoiM203NTFuZmhsZzRjcGg4MzFlMjNlOGdzajQiLCJvcmlnaW5fanRpIjoiNjZkYTcyNWUtMWUwNC00ZWUxLWI3OTMtNzJlNGQ1NDU2OTM4IiwiZXZlbnRfaWQiOiI5MTA4ZDkwOS01YWUzLTQyMGItOTE4YS03OTY0ZjllZjdhMGYiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIiwiYXV0aF90aW1lIjoxNjk5NDMxMTA5LCJleHAiOjE2OTk0MzQ3MDksImlhdCI6MTY5OTQzMTEwOSwianRpIjoiOGJjY2Y3ZjEtZDIwMC00ZjFhLTg0Y2UtYTkwZjM1YTUwMjdiIiwidXNlcm5hbWUiOiJkZXYwMDEifQ.AAJZV3CfHScKKRpGWwoV3RzbjcePtIZMcgnFTUydBxCWrEfvY3P1-HPgmCg1GikmeeigKPp1q3f_JKmb0gxiIulW9YDEttpp8VETaylOsYShdsPKehpIPEoTAj27aISGMNOdPBSqEZiWr8OKbM9LVdt5DIw2p04QeIqoyLK23UySU71uaazcKuudEuI13owhbNR3Zgoxmt052YzFyPS2uj3qUJQ7G8ISAhKMDGZZo7D3pZKD2dIgVsSbFIVjR3DBZ-w8925qLqRi22LLpAmnxkx6YVHNaNpIMDWnDEiBtHRWTRTIIsxkczRLP7c8-dBviEiX8SILb49KYcVKA7roFA'; // From DXC Developer Central
class App extends Component {
  state = { actions: [] };

  /* 
  This example uses DXC Carriers API published in DXC Developer Central.
  The following x-api-key header must be passed to the API and can be obtained
  after registering and signing in Developer Central. 
  More information at https://developer.dxc.com/apis/catalog
  */

  urlProspects = "https://ft1.dxc-assure-policy.com/life/prospects";
  urlPolicyservicecases = "https://ft1.dxc-assure-policy.com/life/policy-servicing/policyservicingcases";
  urlQuote = "https://ft1.dxc-assure-policy.com/life/quotation/v2/quotes";
  urlApplication = "https://ft1.dxc-assure-policy.com/life/application/v2/applications";
  urlBusinesscase="https://ft1.dxc-assure-policy.com/life/businesscases";
  urlUnderwriter="https://ft1.dxc-assure-policy.com/life/uw/underwriter-service/underwritercases";
  urlRequirements="https://ft1.dxc-assure-policy.com/life/requirement";
  // urlCustomerPortal="https://ft1.dxc-assure-policy.com/life/customer-api/customer";
  urlClaims = "https://ft1.dxc-assure-policy.com/life/claimsOffice/claims";
  urlPolicyServicing="https://ft1.dxc-assure-policy.com/life/policy-servicing";
  urlPolicyEnquiryService="https://ft1.dxc-assure-policy.com/life/policy-service";
  urlUserManagement="https://ft1.dxc-assure-policy.com/life/userrole-service/users";
  //PNC
  urlPNCProspects = "https://ft1.dxc-assure-policy.com/pnc/prospects";
  urlPNCNewbusiness = "https://ft1.dxc-assure-policy.com/pnc/quotes";
  urlPNCPayment = "https://ft1.dxc-assure-policy.com/pnc/payments";
  urlPNCOrganization = "https://ft1.dxc-assure-policy.com/pnc/organizationclients";
  urlPNCPolicy = "https://ft1.dxc-assure-policy.com/pnc/policies";
  urlPNCClaimspersona = "https://ft1.dxc-assure-policy.com/pnc/claimpersona-service/claimPersonas";
  urlPNCCallcenter = "https://ft1.dxc-assure-policy.com/pnc/callcenter-service/clientInquiry";
  urlPNCServiceprovider = "https://ft1.dxc-assure-policy.com/pnc/service-provider";
  urlPNCAppointment = "https://ft1.dxc-assure-policy.com/pnc/appointments";

  headersProspects = {
    'Content-Type': 'application/json',
    'Accept': 'application/json,application/hal+json',
    'x-api-key': YOUR_API_KEY
  }


  render() {
    return (
      <div className="App">
      <HalUI url={this.urlProspects} headers={this.headersProspects} />
      <HalUI url={this.urlPolicyservicecases} headers={this.headersProspects} />
      <HalUI url={this.urlQuote} headers={this.headersProspects} />
      <HalUI url={this.urlApplication} headers={this.headersProspects} />
      <HalUI url={this.urlBusinesscase} headers={this.headersProspects} />
      <HalUI url={this.urlUnderwriter} headers={this.headersProspects} />
      <HalUI url={this.urlRequirements} headers={this.headersProspects} />
      {/* <HalUI url={this.urlCustomerPortal} headers={this.headersProspects} /> */}
      <HalUI url={this.urlClaims} headers={this.headersProspects} />
      <HalUI url={this.urlPolicyServicing} headers={this.headersProspects} />
      <HalUI url={this.urlPolicyEnquiryService} headers={this.headersProspects} />
      <HalUI url={this.urlUserManagement} headers={this.headersProspects} />
      <HalUI url={this.urlPNCProspects} headers={this.headersProspects} />
      <HalUI url={this.urlPNCNewbusiness} headers={this.headersProspects} />
      <HalUI url={this.urlPNCPayment} headers={this.headersProspects} />
      <HalUI url={this.urlPNCOrganization} headers={this.headersProspects} />
      <HalUI url={this.urlPNCPolicy} headers={this.headersProspects} />
      <HalUI url={this.urlPNCClaimspersona} headers={this.headersProspects} />
      <HalUI url={this.urlPNCCallcenter} headers={this.headersProspects} />
      <HalUI url={this.urlPNCServiceprovider} headers={this.headersProspects} />
      <HalUI url={this.urlPNCAppointment} headers={this.headersProspects} />
      </div>
    );
  }
}

export default App;
